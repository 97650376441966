/* App.css */
body {
  font-family: Arial, sans-serif;
  background-color: #000000; /* Black background */
  margin: 0;
  padding: 0;
  color: white; /* White text */
}

.App {
  text-align: center;
}

.App-header {
  background-color: #000000;
  padding: 20px;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input[type="text"] {
  padding: 10px;
  margin: 20px;
  font-size: 16px;
  border: 1px solid #FFFF;
  border-radius: 4px;
  width: 80%;
  max-width: 400px;
  background-color: #000000; /* Darker input background */
  color: white;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #000000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 20px;
}

button:hover {
  background-color: #000000;
}

.search-results {
  padding: 20px;
  text-align: left;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  background: #000000; /* Dark background for list items */
  margin: 10px 0;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  color: white;
}

.cve-id {
  font-weight: bold;
  color: white;
}

.cve-name {
  color: white;
}

.cve-date {
  color: white;
}

.cve-score {
  color: white;
}
